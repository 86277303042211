<template>
  <div>
    <b-form
      class="column-header-form"
      @submit.prevent="$emit('dynamic-emits', 'submit')"
    >
      <div class="form-header">
        SELECT COLUMNS
      </div>
      <div class="sort-header">
        <div
          v-for="col in headerColContents"
          :key="col.key"
        >
          <div
            @drop="onDrop($event)"
            @dragover.prevent
            @dragenter.prevent
          >
            <div
              class="column-input drop-zone"
              :index="col.index"
              :class="col.value ? 'kp-opacity-medium' : 'kp-opacity-low'"
              draggable
              @dragstart="startDrag($event, col)"
            >
              <span
                class="three-icons"
                :index="col.index"
              >
                <svg
                  width="10"
                  height="16"
                  viewBox="0 0 10 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M4 14C4 15.1 3.1 16 2 16C0.9 16 0 15.1 0 14C0 12.9 0.9 12 2 12C3.1 12 4 12.9 4 14ZM2 6C0.9 6 0 6.9 0 8C0 9.1 0.9 10 2 10C3.1 10 4 9.1 4 8C4 6.9 3.1 6 2 6ZM2 0C0.9 0 0 0.9 0 2C0 3.1 0.9 4 2 4C3.1 4 4 3.1 4 2C4 0.9 3.1 0 2 0ZM8 4C9.1 4 10 3.1 10 2C10 0.9 9.1 0 8 0C6.9 0 6 0.9 6 2C6 3.1 6.9 4 8 4ZM8 6C6.9 6 6 6.9 6 8C6 9.1 6.9 10 8 10C9.1 10 10 9.1 10 8C10 6.9 9.1 6 8 6ZM8 12C6.9 12 6 12.9 6 14C6 15.1 6.9 16 8 16C9.1 16 10 15.1 10 14C10 12.9 9.1 12 8 12Z"
                    fill="#90A0B7"
                  />
                </svg>
                {{ col.label }}
              </span>
              <svg
                v-if="col.value"
                class="eye-icon"
                :index="col.index"
                width="20"
                height="16"
                viewBox="0 0 20 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                @click="toggle(col.key)"
              >
                <path
                  d="M19.8122 14.7188L 1.12527 0.109688C1.07402 0.0686496 1.01518 0.038109 0.952123 0.0198098C0.889065 0.00151062 0.823021 -0.00418892 0.75776 0.00303664C0.6925 0.0102622 0.629302 0.0302714 0.571775 0.0619217C0.514247 0.093572 0.463517 0.136244 0.42248 0.1875L0.10999 0.577813C0.0688564 0.629087 0.0382372 0.687969 0.0198841 0.75109C0.00153093 0.81421 -0.00419592 0.880331 0.0030312 0.945667C0.0102583 1.011 0.0302976 1.07427 0.0620023 1.13186C0.093707 1.18944 0.136455 1.24021 0.1878 1.28125L18.8747 15.8903C18.926 15.9314 18.9848 15.9619 19.0479 15.9802C19.1109 15.9985 19.177 16.0042 19.2422 15.997C19.3075 15.9897 19.3707 15.9697 19.4282 15.9381C19.4858 15.9064 19.5365 15.8638 19.5775 15.8125L19.89 15.4222C19.9311 15.3709 19.9618 15.312 19.9801 15.2489C19.9985 15.1858 20.0042 15.1197 19.997 15.0543C19.9897 14.989 19.9697 14.9257 19.938 14.8681C19.9063 14.8106 19.8635 14.7598 19.8122 14.7188V14.7188ZM9.27471 4.57719L13.4868 7.87031C13.4174 5.99719 11.8899 4.5 10 4.5C9.75621 4.50046 9.51313 4.52633 9.27471 4.57719ZM10.7253 11.4231L6.51323 8.13C6.58292 10.0028 8.11037 11.5 10 11.5C10.2438 11.4995 10.4868 11.4737 10.7253 11.4231V11.4231ZM10 3.5C13.0827 3.5 15.9089 5.21875 17.4351 8C17.061 8.68449 16.5987 9.31697 16.0601 9.88125L17.2395 10.8031C17.8961 10.1007 18.4524 9.31081 18.8925 8.45594C18.964 8.31453 19.0013 8.1583 19.0013 7.99984C19.0013 7.84139 18.964 7.68516 18.8925 7.54375C17.1963 4.23719 13.8414 2 10 2C8.85316 2 7.75913 2.21875 6.73041 2.58781L8.18068 3.72188C8.77254 3.5875 9.37877 3.5 10 3.5ZM10 12.5C6.91728 12.5 4.09143 10.7812 2.56491 8C2.93952 7.31552 3.40231 6.68314 3.94144 6.11906L2.7621 5.19719C2.1056 5.89952 1.54941 6.6893 1.10933 7.54406C1.03785 7.68547 1.0006 7.8417 1.0006 8.00016C1.0006 8.15861 1.03785 8.31484 1.10933 8.45625C2.80397 11.7628 6.15887 14 10 14C11.1468 14 12.2409 13.7797 13.2696 13.4122L11.8193 12.2784C11.2275 12.4125 10.6215 12.5 10 12.5Z"
                  fill="#90A0B7"
                />
              </svg>
              <svg
                v-if="!col.value"
                class="eye-icon"
                :index="col.index"
                width="18"
                height="12"
                viewBox="0 0 18 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                @click="toggle(col.key)"
              >
                <path
                  d="M9 2.5C8.66879 2.50518 8.34003 2.5578 8.02375 2.65625C8.17005 2.91354 8.24795 3.20403 8.25 3.5C8.25 3.72981 8.20473 3.95738 8.11679 4.1697C8.02884 4.38202 7.89994 4.57493 7.73743 4.73744C7.57493 4.89994 7.38201 5.02884 7.16969 5.11679C6.95737 5.20474 6.72981 5.25 6.5 5.25C6.20403 5.24795 5.91353 5.17005 5.65624 5.02375C5.45325 5.72775 5.47691 6.47777 5.72387 7.16757C5.97083 7.85738 6.42856 8.452 7.03224 8.8672C7.63591 9.28241 8.35492 9.49715 9.08743 9.48101C9.81993 9.46487 10.5288 9.21867 11.1136 8.77728C11.6984 8.33589 12.1295 7.72169 12.3458 7.02168C12.5622 6.32167 12.5528 5.57133 12.319 4.87696C12.0852 4.18258 11.6388 3.57937 11.0432 3.15277C10.4475 2.72616 9.73268 2.49779 9 2.5V2.5ZM17.8913 5.54375C16.1966 2.23719 12.8416 0 9 0C5.15843 0 1.80249 2.23875 0.108733 5.54406C0.0372461 5.68547 0 5.8417 0 6.00016C0 6.15861 0.0372461 6.31484 0.108733 6.45625C1.80342 9.76281 5.15843 12 9 12C12.8416 12 16.1975 9.76125 17.8913 6.45594C17.9628 6.31453 18 6.1583 18 5.99984C18 5.84139 17.9628 5.68516 17.8913 5.54375V5.54375ZM9 10.5C5.91718 10.5 3.09093 8.78125 1.56467 6C3.09093 3.21875 5.91687 1.5 9 1.5C12.0831 1.5 14.9091 3.21875 16.4353 6C14.9094 8.78125 12.0831 10.5 9 10.5Z"
                  fill="#90A0B7"
                />
              </svg>
            </div>
          </div>
          <b-form-checkbox
            v-show="false"
            v-model="col.key"
          />
        </div>
        <div class="d-flex mt-4 justify-content-center">
          <k-button
            variant="info"
            type="submit"
            class="me-4"
            block
          >
            SAVE CHANGES
          </k-button>
          <k-button
            variant="outline-info"
            block
            @click="$emit('dynamic-emits', 'cancel')"
          >
            CANCEL
          </k-button>
        </div>
      </div>
    </b-form>
  </div>
</template>
<script>
import { BForm } from 'bootstrap-vue'
import { KButton } from '@kingpin-global/kingpin-ui'
import { required, email } from '@/@core/utils/validations/validations'

export default {
  name: 'HeaderColumns',
  components: {
    BForm,
    KButton,
  },
  props: {
    componentContent: {
      type: Object,
      default() {
        return null
      },
    },
  },
  data() {
    return {
      adminUserName: '',
      draggedIndex: 0,
    }
  },
  computed: {
    required() {
      return required
    },
    email() {
      return email
    },
    headerColContents() {
      return this.componentContent.headerColumns.slice().sort((a, b) => a.index - b.index)
    },
  },
  methods: {
    startDrag(evt, item) {
      evt.dataTransfer.dropEffect = 'move'
      evt.dataTransfer.effectAllowed = 'move'
      this.draggedIndex = item.index
    },
    onDrop(evt) {
      const droppedIndex = parseInt(evt.toElement.getAttribute('index'))
      this.componentContent.headerColumns.forEach(element => {
        if (element.index === this.draggedIndex) {
          element.index = droppedIndex
        } else if (this.draggedIndex < droppedIndex && element.index > this.draggedIndex && element.index <= droppedIndex) {
          element.index -= 1
        } else if (this.draggedIndex > droppedIndex && element.index < this.draggedIndex && element.index <= droppedIndex) {
          element.index += 1
        }
      })
    },
    toggle(key) {
      this.componentContent.headerColumns = this.componentContent.headerColumns.map(element => {
        if (element.key === key) {
          return { ...element, value: !element.value }
        }
        return element
      })
    },
  },
  emits: ['dynamic-emits'],
}
</script>

<style lang="scss">
@import "../../assets/scss/variables/_variables-components.scss";
@import "../../assets/scss/kingpin-style.scss";
.form-header {
  text-align: left;
  font-family: "Inter";
  font-weight: 700 !important;
  font-size: 16px;
  margin-bottom: 16px;
  color: $form-header-clr;
}
.column-header-form {
  padding: 0% $side-bar-form-padding;
  font-family: "Work Sans";
  .column-input {
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 12px 24px 12px 16px;
    position: static;
    width: 100%;
    height: 48px;
    left: 0px;
    top: 0px;
    background: $col-input-bg;
    border: 1px solid $col-input-border;
    box-sizing: border-box;
    border-radius: 4px;
    margin: 8px 0px;
    font-family: "Inter";
    font-weight: 700 !important;
    font-style: normal;
    font-size: 16px;
    line-height: 16px;
    letter-spacing: 0.03em;
    text-transform: uppercase;
    color: $text-color;
    &:hover {
      .eye-icon {
        visibility: visible;
      }
    }
    .eye-icon {
      visibility: hidden;
    }
  }
}
.form-body{
  max-height: 70vh;
  overflow: auto;
  &::-webkit-scrollbar{
        width: 4px;
      }
      &::-webkit-scrollbar-track{
        background: $scroll-track-color;

      }
      &::-webkit-scrollbar-thumb{
        background-color: $scroll-thumb-color;
        border-radius: 10px;
      }
}
.btn-margin{
  margin-right: 1rem;
}
.sort-header{
  height: 100vh;
  overflow: auto;
  &::-webkit-scrollbar{
    width: 4px;
    height: 4px;
  }
  &::-webkit-scrollbar-track{
    background: $scroll-track-color;
  }
  &::-webkit-scrollbar-thumb{
    background-color: $scroll-thumb-color;
    border-radius: 10px;
  }
}
.three-icons{
  svg {
    margin: 0px 10px;
  }
}
</style>
