/* eslint-disable max-len */
import { extend } from 'vee-validate'
import {
  required as RULE_REQUIRED,
  email as RULE_EMAIL,
  min as RULE_MIN,
  confirmed as RULE_CONFIRMED,
  regex as RULE_REGEX,
  between as RULE_BETWEEN,
  alpha as RULE_ALPHA,
  integer as RULE_INTEGER,
  digits as RULE_DIGITS,
  alpha_dash as RULE_ALPHA_DASH,
  alpha_num as RULE_ALPHA_NUM,
  length as RULE_LENGTH,
  double as RULE_DOUBLE,
  numeric as RULE_NUMERIC,
  min_value as RULE_MIN_VALUE,
  max_value as RULE_MAX_VALUE,
} from 'vee-validate/dist/rules'

import {
  positiveNumberValidator, urlValidator, passwordValidator, creditCardValidator, validatorPhoneNumber,
} from './validators'

// ////////////////////////////////////////////////////////
// General
// ////////////////////////////////////////////////////////

export const required = extend('required', RULE_REQUIRED)

export const email = extend('email', RULE_EMAIL)

export const min = extend('min', RULE_MIN)

export const confirmed = extend('confirmed', RULE_CONFIRMED)

export const regex = extend('regex', RULE_REGEX)

export const between = extend('between', RULE_BETWEEN)

export const alpha = extend('alpha', RULE_ALPHA)

export const integer = extend('integer', RULE_INTEGER)

export const digits = extend('digits', RULE_DIGITS)

export const double = extend('double', RULE_DOUBLE)

export const alphaDash = extend('alpha-dash', RULE_ALPHA_DASH)

export const alphaNum = extend('alpha-num', RULE_ALPHA_NUM)

export const length = extend('length', RULE_LENGTH)

export const numeric = extend('numeric', RULE_NUMERIC)

export const minValue = extend('minValue', RULE_MIN_VALUE)

export const maxValue = extend('maxValue', RULE_MAX_VALUE)

export const positive = extend('positive', {
  validate: positiveNumberValidator,
  message: 'Please enter positive number!',
})

export const credit = extend('credit-card', {
  validate: creditCardValidator,
  message: 'It is not valid credit card!',
})

export const password = extend('password', {
  validate: passwordValidator,
  message: 'Your {_field_} must contain at least one uppercase, one lowercase, one special character and one digit',
})

export const url = extend('url', {
  validate: urlValidator,
  message: 'URL is invalid',
})

export const phNo = extend('phNo', {
  validate: validatorPhoneNumber,
  message: 'Invalid phone number',
})

// ////////////////////////////////////////////////////////
// NOTE:
// Quasar validation for reference only
// Remove this note once development is finished and make sure to
// to convert all of them in veevalidate version
// ////////////////////////////////////////////////////////

// export const required = (val) => {
//   return (val && val.length > 0) || '*Field is required'
// }

// export const required_obj = (obj) => {
//   if (obj === null || obj === undefined) return '*Field is required'
//   return (Object.entries(obj).length > 0 && obj.constructor === Object) || '*Field is required'
// }

// export const no_blank_spaces_arr = (arr) => {
//   return arr.every(val => (val.trim() && val.trim().length > 0)) || 'Blank Spaces are not allowed'
// }

// export const url = val => {
//   // If blank return
//   if (val === undefined || val === null || val.length === 0) return true

//   // Used
//   // https://stackoverflow.com/questions/4314741/url-regex-validation

//   // Other
//   // https://stackoverflow.com/questions/5717093/check-if-a-javascript-string-is-a-url
//   // https://www.w3resource.com/javascript-exercises/javascript-regexp-exercise-9.php
//   // https://www.geeksforgeeks.org/how-to-validate-url-using-regular-expression-in-javascript/

//   /* eslint-disable no-useless-escape */
//   const re = /^(http[s]?:\/\/){0,1}(www\.){0,1}[a-zA-Z0-9\.\-]+\.[a-zA-Z]{2,5}[\.]{0,1}/
//   /* eslint-enable no-useless-escape */
//   return re.test(val) || 'URL is invalid'
// }

// export const date = val => {

//   // If blank return
//   if (val === undefined || val === null || val.length === 0) return true

//   // https://github.com/quasarframework/quasar/blob/dev/ui/src/utils/patterns.js
//   return /^-?[\d]+\/[0-1]\d\/[0-3]\d$/.test(val) || 'Date is invalid'
// }

// export const max = (val, max) => {

//   // If blank return
//   if (val === undefined || val === null) return true

//   return val.length <= max || `More than ${max} characters are not allowed`
// }

// export const max_arr = (val, max) => {
//   return val.length <= max || `More than ${max} values are not allowed`
// }

// export const min = (val, min) => {

//   // If blank return
//   if (val === undefined || val === null || val.length === 0) return true

//   return val.length >= min || `Minimum ${min} characters are required`
// }

// export const num_range = (val, min, max) => {

//   // If blank return
//   if (val === undefined || val === null || val.length === 0) return true

//   const msg = 'Value is invalid'
//   if (min === null) return val <= max || msg
//   else if (max === null) return val >= min || msg
//   else return (val >= min && val <= max) || msg
// }
