import { regexConstants } from '../constants/constants'

export const positiveNumberValidator = value => value >= 0

export const passwordValidator = password => {
  /* eslint-enable no-useless-escape */
  const validPassword = regexConstants.password.test(password)
  return validPassword
}

export const creditCardValidator = creditnum => {
  /* eslint-enable no-useless-escape */
  const validCreditCard = regexConstants.creditCard.test(creditnum)
  return validCreditCard
}

export const urlValidator = val => {
  if (val === undefined || val === null || val.length === 0) {
    return true
  }
  /* eslint-disable no-useless-escape */
  const re = /^(http[s]?:\/\/){0,1}(www\.){0,1}[a-zA-Z0-9\.\-]+\.[a-zA-Z]{2,5}[\.]{0,1}/
  /* eslint-enable no-useless-escape */
  return re.test(val)
}

export const validatorPhoneNumber = phNumber => {
  /* eslint-disable no-useless-escape */
  const regExp = /^[- +()]*[0-9][- +()0-9]*$/
  /* eslint-enable no-useless-escape */
  const validNumber = regExp.test(phNumber)
  return validNumber
}
